var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("listing-component", {
        attrs: {
          type: _vm.listingType,
          "module-name": _vm.moduleName,
          headers: _vm.headers,
          "sort-by": "execution_date",
          "overridden-columns": _vm.overriddenColumns,
          "show-delete-action": "",
        },
        scopedSlots: _vm._u([
          {
            key: "configuration_id",
            fn: function ({ item: { configuration_id } }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.tablesToTablesRouteName,
                        params: { id: configuration_id },
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(configuration_id)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "expectation_result.tablename",
            fn: function ({ item: { expectation_result } }) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: _vm.getTableNameLink(expectation_result) } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(
                        _vm._s(_vm.getFormattedTableName(expectation_result))
                      ),
                    ]),
                  ]
                ),
                _c("span", [_vm._v(" - ")]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.getTableNameExternalLink(expectation_result),
                      target: "_blank",
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "google-cloud-icon" }, [
                      _vm._v(_vm._s(_vm.googleCloudIcon)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "expectation_result.column_name",
            fn: function ({ item: { expectation_result } }) {
              return [
                _vm._v(" " + _vm._s(expectation_result.procedure_name) + " "),
                expectation_result.column_name
                  ? _c("span", [
                      _vm._v(
                        "(" + _vm._s(expectation_result.column_name) + ")"
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "task_criticality",
            fn: function ({ item: { task_criticality } }) {
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color: _vm.getTaskCriticalityChipColor(task_criticality),
                      "text-color":
                        _vm.getTaskCriticalityChipTextColor(task_criticality),
                      small: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(task_criticality) + " ")]
                ),
              ]
            },
          },
          {
            key: "expectation_result.passed",
            fn: function ({ item: { expectation_result } }) {
              return [
                expectation_result.passed
                  ? _c("v-icon", { attrs: { color: "success" } }, [
                      _vm._v("mdi-check"),
                    ])
                  : _c("v-icon", { attrs: { color: "error" } }, [
                      _vm._v("mdi-alert-octagon-outline"),
                    ]),
              ]
            },
          },
          {
            key: "execution_date",
            fn: function ({
              item: {
                configuration_id,
                configuration_type,
                run_id,
                execution_date,
              },
            }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.runRouteName,
                        params: { id: `${configuration_id}_${run_id}` },
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(execution_date, "YYYY/MM/DD - HH:mm")
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "duration",
            fn: function ({ item: { expectation_result } }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormattedDuration(
                        _vm.$moment
                          .unix(expectation_result.end_date)
                          .diff(
                            _vm.$moment.unix(expectation_result.start_date),
                            "seconds"
                          )
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }