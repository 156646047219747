var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("listing-component", {
        ref: "listingComponent",
        attrs: {
          type: _vm.listingType,
          "module-name": _vm.moduleName,
          headers: _vm.headers,
          "sort-by": "execution_date",
          "overridden-columns": _vm.overriddenColumns,
          "show-delete-action": "",
          "custom-data-fetching": _vm.getFreshnessDocuments,
        },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function () {
              return _vm._l(_vm.filters, function (filter) {
                return _c(
                  "v-menu",
                  {
                    key: filter.selectedValue.label,
                    attrs: { transition: "slide-y-transition", bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mr-3",
                                    attrs: { small: "", outlined: "" },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(filter.selectedValue.label) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("arrow_drop_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      _vm._l(filter.values, function (option) {
                        return _c(
                          "v-list-item",
                          {
                            key: option.value,
                            on: {
                              click: function ($event) {
                                return filter.clickAction(option)
                              },
                            },
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(option.label)),
                            ]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              })
            },
            proxy: true,
          },
          {
            key: "job_id",
            fn: function ({
              item: { configuration_id, configuration_type, job_id },
            }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.getConfigurationRouteName(configuration_type),
                        params: { id: configuration_id },
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(job_id)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "last_execution_datetime",
            fn: function ({ item: { last_execution_datetime } }) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        last_execution_datetime,
                        "YYYY/MM/DD - HH:mm"
                      )
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "next_execution_datetime",
            fn: function ({ item: { next_execution_datetime } }) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        next_execution_datetime,
                        "YYYY/MM/DD - HH:mm"
                      )
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function ({ item: { status } }) {
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "text-uppercase",
                    attrs: {
                      color: _vm.getStatusColor(status),
                      "text-color": "white",
                      small: "",
                      label: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(status) + " ")]
                ),
              ]
            },
          },
          {
            key: "timing",
            fn: function ({ item: { timing } }) {
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "text-uppercase",
                    attrs: {
                      color: _vm.getTimingColor(timing),
                      "text-color": "white",
                      small: "",
                      label: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(timing) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }