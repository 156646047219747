export enum Frequencies {
	All = 'All',
	Daily = 'DAILY',
	Weekly = 'WEEKLY',
	Monthly = 'MONTHLY',
	Yearly = 'YEARLY',
	Unfollow = 'UNFOLLOW',
}

export enum Statuses {
	All = 'All',
	Archived = 'ARCHIVED',
	Executed = 'EXECUTED',
	Awaited = 'AWAITED',
	NotToday = 'NOT TODAY',
	Learning = 'LEARNING',
}

export enum Timings {
	All = 'All',
	OnTime = 'ON TIME',
	Late = 'LATE',
	VeryLate = 'VERY LATE',
	NA = 'NA',
	Learning = 'LEARNING',
}
