import { Component, Vue } from 'vue-property-decorator';
import { FRESHNESS } from '@/constants/data-quality/names';
import { FRESHNESS_LISTING } from '@/constants/router/routes-names';

@Component
export default class HeaderInfos extends Vue {
	get workflowName() {
		return FRESHNESS.displayName;
	}

	get tabsItems() {
		return [{ id: 1, title: 'Runs', link: { name: FRESHNESS_LISTING } }];
	}
}
