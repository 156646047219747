
import { collectionGroup, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import HeaderInfosMixin from './header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';
import ConfigurationRouteName from '@/mixins/common/configuration-route-name';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';
import { AnyObject } from '@/types';
import { freshness } from '@/store/modules/easy-firestore/freshness';
import { STATUS } from '@/constants/data-operations/listing/header-items';
import { FRESHNESS } from '@/constants/data-operations/status';
import {
	Frequencies,
	Statuses as FreshnessStatuses,
	Timings as FreshnessTimings,
} from '@/constants/data-quality/freshness';

enum Statuses {
	executed = 'EXECUTED',
	awaited = 'AWAITED',
	notToday = 'NOT TODAY',
	learning = 'LEARNING',
}

enum Timings {
	onTime = 'ON TIME',
	late = 'LATE',
	veryLate = 'VERY LATE',
	learning = 'LEARNING',
	NA = 'NA',
}

type Option = { label: string; value: any };

@Component
export default class ExpectationsRunsListingView extends Mixins(
	HeaderInfosMixin,
	CollectionMixin,
	ConfigurationRouteName
) {
	moduleName = freshness.moduleName;
	overriddenColumns = ['job_id', 'last_execution_datetime', 'next_execution_datetime', 'status', 'timing'];
	freshness = {
		configurationType: {
			label: 'All conf types',
			value: 'All',
		},
		frequency: {
			label: 'All frequencies',
			value: Frequencies.All,
		},
		status: {
			label: 'All statuses',
			value: FreshnessStatuses.All,
		},
		timing: {
			label: 'All timings',
			value: FreshnessTimings.All,
		},
	};

	@Watch('freshness', { deep: true })
	onFreshnessChange() {
		(this.$refs.listingComponent as ListingComponent).getFirestoreData();
	}

	async getFreshnessDocuments() {
		let runsQueryConstraints = [
			where('account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id),
			where('collection_groupe_name', '==', 'tailer-activities-runs-freshness'),
			orderBy('last_execution_datetime', 'desc'),
		];

		if (this.freshness.configurationType.value !== 'All')
			runsQueryConstraints.push(where('configuration_type', '==', this.freshness.configurationType.value));
		if (this.freshness.frequency.value !== 'All')
			runsQueryConstraints.push(where('frequence', '==', this.freshness.frequency.value));
		if (this.freshness.status.value !== 'All') {
			if (this.freshness.status.value === FreshnessStatuses.Archived) {
				runsQueryConstraints.push(where('archived', '==', true));
			} else {
				runsQueryConstraints.push(where('status', '==', this.freshness.status.value));
			}
		}
		if (this.freshness.timing.value !== 'All')
			runsQueryConstraints.push(where('timing', '==', this.freshness.timing.value));

		const runs = query(collectionGroup(getFirestore(), 'freshness'), ...runsQueryConstraints);
		const querySnapshot = await getDocs(runs);
		const items: AnyObject[] = [];

		querySnapshot.forEach((doc) => items.push({ ...doc.data(), id: doc.id }));
		return items;
	}

	getStatusColor(status: Statuses) {
		let statusColor = '';

		switch (status) {
			case Statuses.executed:
				statusColor = 'success';
				break;
			case Statuses.awaited:
				statusColor = 'blue';
				break;
			case Statuses.learning:
				statusColor = 'deep-purple lighten-3';
				break;
			case Statuses.notToday:
				statusColor = 'grey';
				break;
		}

		return statusColor;
	}

	getTimingColor(timing: Timings) {
		let timingColor = '';

		switch (timing) {
			case Timings.onTime:
				timingColor = 'success';
				break;
			case Timings.late:
				timingColor = 'orange';
				break;
			case Timings.veryLate:
				timingColor = 'red';
				break;
			case Timings.learning:
				timingColor = 'deep-purple lighten-3';
				break;
			case Timings.NA:
				timingColor = 'grey';
				break;
		}

		return timingColor;
	}

	get listingType() {
		return FRESHNESS;
	}

	get headers() {
		return [
			{ text: 'Configuration Type', value: 'configuration_type', sortable: true, width: 190 },
			{ text: 'Job ID', value: 'job_id', sortable: true },
			{ text: 'Last Execution', value: 'last_execution_datetime', sortable: true, width: 170 },
			{ text: 'Next Execution', value: 'next_execution_datetime', sortable: true, width: 170 },
			{ text: 'Confidence', value: 'confidence', sortable: true, align: 'center', width: 100 },
			{ text: 'Frequency', value: 'frequence', sortable: true, align: 'center' },
			STATUS,
			{ text: 'Timing', value: 'timing', sortable: true },
		];
	}

	get filters() {
		return [
			{
				values: [
					{ label: 'All conf types', value: 'All' },
					{ label: 'storage-to-storage', value: 'storage-to-storage' },
					{ label: 'workflow', value: 'workflow' },
					{ label: 'vm-launcher', value: 'vm-launcher' },
					{ label: 'storage-to-tables', value: 'storage-to-tables' },
					{ label: 'table-to-storage', value: 'table-to-storage' },
					{ label: 'tables-to-tables', value: 'tables-to-tables' },
					{ label: 'xml-conversion', value: 'xml-conversion' },
					{ label: 'api-to-storage', value: 'api-to-storage' },
				],
				selectedValue: this.freshness.configurationType,
				clickAction: (option: Option) => (this.freshness.configurationType = option),
			},
			{
				values: [
					{ label: 'All frequencies', value: Frequencies.All },
					{ label: 'Daily', value: Frequencies.Daily },
					{ label: 'Weekly', value: Frequencies.Weekly },
					{ label: 'Monthly', value: Frequencies.Monthly },
					{ label: 'Yearly', value: Frequencies.Yearly },
					{ label: 'Unfollow', value: Frequencies.Unfollow },
				],
				selectedValue: this.freshness.frequency,
				clickAction: (option: Option) => (this.freshness.frequency = option),
			},
			{
				values: [
					{ label: 'All statuses', value: FreshnessStatuses.All },
					{ label: 'Executed', value: FreshnessStatuses.Executed },
					{ label: 'Awaited', value: FreshnessStatuses.Awaited },
					{ label: 'Not today', value: FreshnessStatuses.NotToday },
					{ label: 'Learning', value: FreshnessStatuses.Learning },
					{ label: 'Archived', value: FreshnessStatuses.Archived },
				],
				selectedValue: this.freshness.status,
				clickAction: (option: Option) => (this.freshness.status = option),
			},
			{
				values: [
					{ label: 'All timings', value: FreshnessTimings.All },
					{ label: 'On time', value: FreshnessTimings.OnTime },
					{ label: 'Late', value: FreshnessTimings.Late },
					{ label: 'Very late', value: FreshnessTimings.VeryLate },
					{ label: 'NA', value: FreshnessTimings.NA },
					{ label: 'Learning', value: FreshnessTimings.Learning },
				],
				selectedValue: this.freshness.timing,
				clickAction: (option: Option) => (this.freshness.timing = option),
			},
		];
	}
}
