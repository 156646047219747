
import { AnyObject } from '@/types';
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from './header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';

import { mdiGoogleCloud } from '@mdi/js';
import { tailerExpectations } from '@/store/modules/easy-firestore/tailer-expectations';
import {
	DATA_TABLE_DETAILS,
	TABLES_TO_TABLES_CONFIGURATIONS_ITEM,
	TABLES_TO_TABLES_RUNS_ITEM,
} from '@/constants/router/routes-names';
import { CONFIGURATION_ID, DURATION, ENVIRONMENT } from '@/constants/data-operations/listing/header-items';
import { EXPECTATIONS } from '@/constants/data-operations/status';
import { BIG_QUERY_URL } from '@/constants/urls/external';

@Component
export default class ExpectationsRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {
	moduleName: string = tailerExpectations.moduleName;
	overriddenColumns: string[] = [
		'configuration_id',
		'expectation_result.tablename',
		'expectation_result.column_name',
		'task_criticality',
		'expectation_result.passed',
		'execution_date',
		'duration',
	];

	getTableNameLink(expectationResult: AnyObject) {
		const [projectId, datasetId] = expectationResult.dataset.split('.');
		return {
			name: DATA_TABLE_DETAILS,
			params: {
				projectId,
				datasetId,
				tableId: expectationResult.tablename,
			},
		};
	}

	getTableNameExternalLink(expectationResult: AnyObject) {
		const [projectId, datasetId] = expectationResult.dataset.split('.');
		return `${BIG_QUERY_URL}?project=${projectId}&p=${projectId}&d=${datasetId}&t=${expectationResult.tablename}&page=table`;
	}

	getFormattedTableName(expectationResult: AnyObject) {
		const length = 50;
		const totalName = `${expectationResult.dataset}.${expectationResult.tablename}`;
		return totalName.length > length ? `${totalName.substr(0, length)}...` : totalName;
	}

	getTaskCriticalityChipColor(taskCriticality: string) {
		switch (taskCriticality) {
			case 'critical':
				return 'purple';
			case 'warning':
				return 'orange';
			default:
				return '';
		}
	}

	getTaskCriticalityChipTextColor(taskCriticality: string) {
		switch (taskCriticality) {
			case 'critical':
				return 'white';
			case 'warning':
				return 'black';
			default:
				return '';
		}
	}

	get listingType() {
		return EXPECTATIONS;
	}

	get headers() {
		return [
			ENVIRONMENT,
			CONFIGURATION_ID,
			{ text: 'Table', value: 'expectation_result.tablename', sortable: true, width: 430 },
			{ text: 'Expectation', value: 'expectation_result.column_name', sortable: true },
			{ text: 'Criticality', value: 'task_criticality', sortable: true },
			{ text: 'Passed', value: 'expectation_result.passed', sortable: true },
			{ text: 'Execution Date', value: 'execution_date', sortable: true },
			DURATION,
		];
	}

	get googleCloudIcon() {
		return mdiGoogleCloud;
	}

	get tablesToTablesRouteName() {
		return TABLES_TO_TABLES_CONFIGURATIONS_ITEM;
	}

	get runRouteName() {
		return TABLES_TO_TABLES_RUNS_ITEM;
	}
}
