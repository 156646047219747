import { Component, Vue } from 'vue-property-decorator';
import { EXPECTATIONS_OVERVIEW } from '@/constants/data-quality/names';
import { EXPECTATIONS_OVERVIEW_LISTING } from '@/constants/router/routes-names';

@Component
export default class HeaderInfos extends Vue {
	get workflowName() {
		return EXPECTATIONS_OVERVIEW.displayName;
	}

	get tabsItems() {
		return [{ id: 1, title: 'Runs', link: { name: EXPECTATIONS_OVERVIEW_LISTING } }];
	}
}
